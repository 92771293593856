.color-filter
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: center
    gap: 10px

    &__button
        width: 20px
        height: 20px
        border-radius: 50%
        cursor: pointer
        position: relative

        &--active::after
            content: ""
            position: absolute
            top: -4px
            left: -4px
            right: -4px
            bottom: -4px
            border: 3px ridge rgba(101, 187, 233, 0.6)
            border-radius: inherit
            pointer-events: none
