// mosaic.scss

.mosaic-display {
    display: grid;
    gap: 10px;
    margin-bottom: 40px;
    grid-template-areas:
        "a a a c c d d f f i i i i"
        "a a a c c d d f f i i i i"
        "a a a e e e e g g i i i i"
        "b b b e e e e g g i i i i"
        "b b b e e e e h h j j k k"
        "b b b e e e e h h j j k k"
        "l l l l m m r r s s u u u"
        "l l l l m m r r s s u u u"
        "l l l l n n t t t t u u u"
        "l l l l n n t t t t v v v"
        "o o p p q q t t t t v v v"
        "o o p p q q t t t t v v v";
  
    &__item {
        position: relative;
    
        &::before {
            content: '';
            display: block;
            padding-top: 100%; // 正方形にする場合
        }
    
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    
        &--0 { grid-area: a; }
        &--1 { grid-area: b; }
        &--2 { grid-area: c; }
        &--3 { grid-area: d; }
        &--4 { grid-area: e; }
        &--5 { grid-area: f; }
        &--6 { grid-area: g; }
        &--7 { grid-area: h; }
        &--8 { grid-area: i; }
        &--9 { grid-area: j; }
        &--10 { grid-area: k; }
        &--11 { grid-area: l; }
        &--12 { grid-area: m; }
        &--13 { grid-area: n; }
        &--14 { grid-area: o; }
        &--15 { grid-area: p; }
        &--16 { grid-area: q; }
        &--17 { grid-area: r; }
        &--18 { grid-area: s; }
        &--19 { grid-area: t; }
        &--20 { grid-area: u; }
        &--21 { grid-area: v; }
    }
}  