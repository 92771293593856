.filter-sidebar
    padding: 10px
    background-color: #f8f9fa
    border-radius: 12px
    width: 200px

    &__title
        font-size: 1rem
        padding: 10px
        padding-bottom: 0
        margin-bottom: 10px

    &__section
        margin-bottom: 4px
        background: #ffffff
        padding: 10px
        border-radius: 8px

    &__header
        display: flex
        justify-content: space-between
        cursor: pointer
        font-size: 0.7rem
        font-weight: bolder

    &__content
        margin-top: 10px

    &__buttons
        margin-top: 20px
        width: 100%
        display: flex
        gap: 10px
        align-items: center
        justify-content: center
    &__button
        padding: 4px 12px
        border: 0.6px solid #ddd
        border-radius: 6px
        background: #ffffff
        cursor: pointer
        transition: background 0.3s
        &--apply
            color: #fff
            font-weight: bold
            background: $color-link
        &--clear
            background: #ddd

    &__search-input
        width: 100%
        padding: 8px
        border: 1px solid #ddd
        border-radius: 4px
        margin-bottom: 10px

    &__brand-list
        list-style: none
        padding: 0

    &__brand-label
        display: flex
        justify-content: space-between
        padding: 5px 0
