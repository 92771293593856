// home.scss

.home-wrapper
  display: flex
  justify-content: space-between
  width: 980px
  @include tablet
    width: 100vw

  padding: 40px 0 60px
  margin: 0 auto
  @include sp
    margin-top: 0
    padding-top: 0

  .home-right
    position: relative
    z-index: 0
    order: 1
    width: 745px
    @include sp
      width: 100%

  .home-left
    position: relative
    z-index: 1
    width: 180px

    &-title
      margin-bottom: 3px

      h2
        font-weight: bold
        overflow: hidden
        text-overflow: ellipsis
        display: -webkit-box
        -webkit-box-orient: vertical
        -webkit-line-clamp: 2
        line-clamp: 2
        font-size: 20px
        line-height: 1.7
        color: $color-text

    &-ul
      li
        margin-top: 12px

        a
          font-size: 13px
          color: $color-text

          &:hover
            text-decoration: underline
