.magazine-display {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1.2%;
}

.magazine-card {
    width: 100%;
    overflow: hidden;
    &__contents {
        display: grid;
        grid-template-columns: 2fr 3fr;
        &--left {
            width: 100%;
        }
        &--right {
            max-width: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}