.size-filter
    display: flex
    flex-wrap: wrap
    gap: 10px

    &__button
        padding: 4px 12px
        border: 0.6px solid #ddd
        border-radius: 6px
        background: #ffffff
        cursor: pointer
        transition: background 0.3s

        &:hover
            background: #f0f0f0
        &--active
            border: 3px ridge rgba(101, 187, 233, 0.6)
