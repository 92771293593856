// mixins.scss

@mixin flex-center
  display: flex
  justify-content: center
  align-items: center

@mixin transition($properties...)
  transition: $properties

@mixin hover-bg($color)
  &:hover
    background-color: $color

@mixin font-smoothing
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

@mixin clearfix
  &::after
    content: ''
    display: table
    clear: both

@mixin aspect-ratio($width, $height)
  position: relative
  width: 100%
  padding-top: ($height / $width) * 100%
  overflow: hidden

  > img, > video, > iframe, > .content
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

@mixin category-popup-styles
  position: absolute
  z-index: 1
  width: fit-content
  background-color: #fff
  border-radius: 8px
  transform: translate(-20px, -12px)
  top: 0
  left: 100%
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px

// Media Queries
@mixin sp-xs
  @media(max-width:400px)
    @content

@mixin sp
  @media(max-width:575px)
    @content

@mixin tablet
  @media(max-width:767px)
    @content

@mixin pc
  @media(max-width:991px)
    @content

@mixin pc-lg
  @media(max-width:1199px)
    @content

@mixin desktop-xl
  @media(max-width:1400px)
    @content

@mixin desktop-1500
  @media(min-width:1500px)
    @content

@mixin desktop-4k
  @media(min-width:2000px)
    @content

@mixin size($size)
  width: $size
  height: $size
