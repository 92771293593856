.price-filter
    width: 100%
    padding-top: 8px

    &__fields
        width: 100%
        font-size: 19px
        color: #555
        display: flex
        justify-content: space-between

    &__field
        width: 42%
        margin-bottom: 22px
        display: flex
        align-items: flex-end
        gap: 2px
        font-size: 0.65rem
        font-family: "DM Sans", sans-serif

        &-input
            height: 24px
            width: 100%
            border-radius: 4px
            text-align: center
            border-width: 1px
            border-style: solid
            border-color: #e4e4e4
            // background: #e4e4e4

    &__field-input::-webkit-outer-spin-button,
    &__field-input::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0

    &__slider-container
        width: 100%
        height: 3px
        position: relative
        background: #f4f4f4
        border-radius: 5px

    &__slider-track
        height: 100%
        position: absolute
        border-radius: 5px
        background: #65BBE9

    &__ranges
        position: relative
        margin-bottom: 12px

    &__range-input
        position: absolute
        width: 100%
        height: 2px
        background: none
        top: -2px
        pointer-events: none
        cursor: pointer
        -webkit-appearance: none

        &::-webkit-slider-thumb
            height: 16px
            width: 16px
            border-radius: 70%
            background: #fff
            border: 2px solid #65BBE9
            pointer-events: auto
            -webkit-appearance: none
