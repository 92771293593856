.sidebar
    &__header
        display: flex
        align-items: center
        justify-content: space-between
        position: relative
        padding: 10px 0
        margin-bottom: 6px
        h2, h3
            font-size: 0.875rem // 14px相当
            font-weight: bold
            margin-bottom: 0
            color: $color-text
        &::after
            content: ""
            flex-grow: 1
            height: 1px
            background-color: rgba(45, 45, 45, 0.05)
            margin-left: 10px

    &__list
        position: relative
        li
            display: flex
            align-items: center
            justify-content: space-between
            padding: 6px 10px
            font-size: 13px
            color: #2d2d2d
            cursor: pointer
            &:hover
                background-color: rgba(45, 45, 45, 0.05)
    &__chevron
        flex-shrink: 0
        width: 6px
        height: 20px
        margin-left: 10px
        fill: #a0a0a0

    &__menu
        list-style: none
        margin: 0
        padding: 0

    &__link
        display: flex
        align-items: center
        text-decoration: none
        color: #374151 // 例: ダークグレー系
        font-size: 0.875rem
        padding: 0.5rem 0.25rem
        border-radius: 4px
        transition: background-color 0.2s

        &:hover
            background-color: #e5e7eb // hover時の背景色

    &__icon
        display: inline-block
        width: 1.25rem
        margin-right: 0.75rem // アイコンとテキストの隙間
        text-align: center
        // アイコンフォントを利用している場合、font-sizeやline-heightなど調整

    &__badge
        margin-left: 0.5rem
        background-color: #ecfdf5 // 例: ライトグリーン
        color: #065f46 // 例: グリーン系
        font-size: 0.75rem // 12px相当
        padding: 2px 6px
        border-radius: 9999px // pill 形状

    &__submenu
        list-style: none
        margin: 0.25rem 0 0 2rem // 少しインデント
        padding: 0

        li
            margin-bottom: 0.25rem

        a
            font-size: 0.875rem
            color: #374151
            text-decoration: none

            &:hover
                text-decoration: underline
