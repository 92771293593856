// OrderComplete.sass
// Block: order-complete

.order-complete
    display: flex
    justify-content: center
    align-items: center
    min-height: 80vh
    padding: 20px
    animation: fadeIn 1s ease-out

    &__content
        background-color: #fff
        border-radius: 8px
        padding: 2rem
        text-align: center
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2)
        animation: fadeInUp 0.8s ease-out
        position: relative

    // SVGパッケージ（箱）のスタイル
    &__package
        width: 80px
        height: 80px
        margin: 0 auto 1rem
        display: block
        overflow: visible

    &__package-body
        // 箱本体のスタイルはSVG内で指定しているので必要に応じて調整

    &__package-lid
        transform-origin: center bottom
        animation: lidOpen 1s ease-out forwards

    // コンフェッティコンテナ
    &__confetti
        position: absolute
        top: 10%
        left: 0
        width: 100%
        height: 0
        pointer-events: none

    &__confetti-piece
        position: absolute
        bottom: 0
        width: 8px
        height: 8px
        border-radius: 50%
        opacity: 0
        animation: confetti 1s ease-out forwards

    &__confetti-piece--1
        background-color: #f44336
        left: 20%
        animation-delay: 0.5s

    &__confetti-piece--2
        background-color: #ffeb3b
        left: 50%
        animation-delay: 0.7s

    &__confetti-piece--3
        background-color: #2196f3
        left: 80%
        animation-delay: 0.9s

    &__title
        font-size: 1.8rem
        margin-bottom: 1rem
        color: #333
        animation: fadeIn 1s ease forwards

    &__message
        font-size: 1.1rem
        color: #555
        margin-bottom: 2rem
        animation: fadeIn 1.2s ease forwards

    &__button
        cursor: pointer
        transition: background-color 0.3s ease, transform 0.2s ease
        &:hover
            transform: translateY(-2px)
        &:active
            transform: translateY(0)
