// product.scss

.product-list {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    isolation: isolate;
    column-gap: 1.97%;
    &--shop {
      grid-template-columns: repeat(8, minmax(0, 1fr));
      column-gap: 1%;
    }
    &--card {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      column-gap: 1.2%;
      .product__img-wrapper {
        overflow: hidden;
        &:hover img {
          transform: scale(1.1);
        }
        img {
          @include transition(transform 0.5s ease);
        }
      }
    }
    @include sp {
      grid-template-columns: repeat(3, minmax(0, 1fr));;
      column-gap: 1.97%;
    }
}

.product {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1.618;
  height: auto;
  padding-bottom: 6px;
  margin-bottom: 24px;
  @include sp {
    padding-bottom: 0px;
  }
  
  // &--list {
  //   // `.product--list` に特有のスタイルをここに追加
  //   aspect-ratio: 1 / 1.5;
  // }
  
  &--gallery {
    padding-bottom: 20px;
    @include sp {
      padding-bottom: 12px;
    }
    .product__body {
      padding: 10px 10px 0;
    }
  }
  
  &__img-wrapper {
    display: block;
    width: 100%;
    aspect-ratio: 1 / 1.414;
    
    img {
      width: 100%;
      aspect-ratio: 1 / 1.414;
      object-fit: cover;
    }
  }
  
  &__body {
    background: $color-background;
    width: 100%;
    aspect-ratio: 1 / 0.204;
    line-height: 1.5;
    color: $color-text;
    padding-top: 4px;
    
    @include sp {
      padding: 10px 10px 0;
    }
  }
  
  &__title {
    display: block;
    font-size: 12px;
    
    @include sp {
      font-size: 10px;
    }
    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  &__caption {
    font-size: 12px;
    
    @include sp {
      font-size: 10px;
      margin-top: 5px;
    }
    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
  }
  
  &__price-wrapper {
    @include flex-center;
    justify-content: space-between;
  }
  
  &__price {
    font-size: 16px;
    
    @include sp {
      font-size: 14px;
    }
    
    margin-top: 7px;
    font-weight: 600;
  }
  
  &__delete-btn {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.6);
    border: none;
    box-shadow: none;
    color: gray;
    border-radius: 0;
    
    &:hover {
      background: rgba(45, 45, 45, 0.4);
      color: $color-background;
    }
  }
}