
@keyframes fadeInUp
    from
        opacity: 0
        transform: translateY(20px)
    to
        opacity: 1
        transform: translateY(0)

@keyframes fadeIn
    from
        opacity: 0
    to
        opacity: 1

@keyframes lidOpen
    from
        transform: rotate(0deg)
    to
        transform: rotate(-30deg)

@keyframes confetti
    0%
        opacity: 1
        transform: translateY(50px) rotate(0deg)
    100%
        opacity: 0
        transform: translateY(-50px) rotate(360deg)
