// variables.scss

// カラーパレット
$color-heading: #2d2d2d
$color-text: #2d2d2d
$color-background: #fff
// $color-background: #ccc5b7;
$color-bg-f8f8f8: #f8f8f8
$color-link: #23abdd
$color-border: rgba(45, 45, 45, 0.07)
$color-rating: #f8e825
$color-notification-dot: red
$color-overlay: rgba(0, 0, 0, 0.5)

$color-dropdown-item: #000b29
$color-review-bg: #f4f4f4
$color-review-border: #ddd
$color-border-button: rgba(45, 45, 45, 0.07)
$color-hover-bg: rgba(45, 45, 45, 0.05)
$color-rating-shop: #f8e825

$color-header-bg: #efefef
$color-chevron: #a0a0a0
$color-rating-svg: #f8e825

// フォント
$font-main: 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'メイリオ', 'Lucida Grande', Verdana, sans-serif
$font-size-base: 13px
$font-size-small: 0.8rem
$font-size-large: 1.2rem
$line-height-base: 1.6

// レスポンシブブレークポイント
$breakpoint-sm: 576px
$breakpoint-md: 768px
$breakpoint-lg: 992px
$breakpoint-xl: 1200px
